@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
#global_header, #contents_wrap, #global_footer, section.blog {
  display: none;
}

section div.form_wrap dl {
  margin-bottom: -1px !important;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 300;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 300;
  color: #fff;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  letter-spacing: 0.05em;
  font-size: 16px;
  background: url(../images/contents/pt_4.gif);
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding: 0 0 100px 0;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding: 0 10px 50px 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  z-index: 100;
  position: relative;
  z-index: 99999;
}

.anc {
  position: absolute;
  left: 0;
  z-index: 10;
  color: #fff !important;
}

.anc #ancnavi i, .anc #ancnavi .bd {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out,transform 0.3s ease-in-out;
}

.anc #ancnavi i {
  font-size: 30px;
  font-weight: 100;
}

.anc #ancnavi .bd {
  width: 60px;
  height: 60px;
  border: solid 2px #FFFFFF;
}

#mainArea {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

#mainArea button {
  display: none !important;
}

#mainArea ul {
  z-index: 0;
}

#mainArea ul li img {
  width: 100% !important;
}

#mainArea .maintxt {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#mainArea .maintxt h1 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mainArea .maintxt h1 .cls-1 {
  font-weight: 700;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

#mainArea .maintxt h1 svg {
  max-width: 700px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  #mainArea .maintxt h1 svg {
    max-width: 500px;
  }
}

@media only screen and (max-width: 640px) {
  #mainArea .maintxt h1 svg {
    max-width: 350px;
  }
}

#mainArea .maintxt h1 path {
  fill: none;
  stroke: #fff;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  stroke-width: 1;
  animation: hello 3s ease-in forwards;
}

@media all and (-ms-high-contrast: none) {
  #mainArea .maintxt h1 path {
    fill: #fff !important;
  }
}

@-webkit-keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

@keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

#header {
  position: fixed;
  z-index: 1;
  top: 30px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  #header {
    padding: 0 10px;
  }
}

#header .inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#header .inner .flxL {
  width: 30%;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxL {
    width: 50%;
  }
}

#header .inner .flxR {
  width: 67%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR {
    width: 50%;
  }
}

#header .inner .flxR .tel2 {
  margin-right: 20px;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .tel2 {
    margin-right: 0;
    margin-left: 10px;
  }
}

#header .inner .flxR .tel2 a {
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  display: block;
  transition: 1.5s;
  opacity: 1;
  padding: 10px 30px;
  background: url(../images/common/pt_1.gif) center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .tel2 a {
    padding: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
  }
}

#header .inner .flxR .tel2 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

#header .inner .flxR .tel2 a i {
  margin-right: 7px;
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .tel2 a i {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  #header .inner .flxR .tel2 a span {
    display: none;
  }
}

#header .inner .flxR .tel2 a:hover {
  opacity: 1;
}

#header .inner .flxR .tel2 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.global-menu-btn {
  margin-left: auto;
  cursor: pointer;
  height: 60px;
  width: 60px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 11;
  line-height: 1;
  transition: 1.5s;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  background: url(../images/common/pt_1.gif) center;
  position: relative;
  overflow: hidden;
}

.global-menu-btn:hover {
  opacity: 1;
}

.global-menu-btn:hover:after {
  left: 140%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.global-menu-btn::before {
  font-size: 2.4em;
  content: "\f0c9";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
}

.global-menu-btn:after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.global-menu-btn i {
  margin-top: 3px;
  display: block;
  line-height: 1;
}

.global-menu-close-btn {
  display: block;
  height: 100px;
  width: 100px;
  margin-left: auto;
  background: #f4f7f6;
  border-left: 1px solid #E6EAEA;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    height: 70px;
    width: 70px;
  }
}

.global-menu-close-btn:before {
  content: "\f00d";
  line-height: 32px;
  font-family: "Font Awesome 5 pro";
  font-size: 1.3rem;
  font-weight: 300;
  display: block;
  width: 100%;
  color: #000;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (-ms-high-contrast: none) {
  .global-menu-close-btn:before {
    display: none;
  }
}

.global-menu-close-btn i {
  display: none;
}

.navdamy {
  width: 100%;
  height: 100%;
  position: absolute;
}

#hednav {
  display: block;
  margin-bottom: 0;
  justify-content: center;
  height: 60px;
  width: 60px;
  z-index: 11;
  margin: 0;
}

@media only screen and (max-width: 834px) {
  #hednav {
    margin-left: 10px;
  }
}

#hednav #siteID {
  max-width: 60%;
  margin: 0 auto 30px;
}

#hednav .wrap {
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  background: rgba(0, 0, 0, 0.6) !important;
  display: block !important;
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  right: 0;
}

#hednav .wrap .navwrap {
  display: block !important;
  background: #eef2f2;
  width: 40%;
  height: 100%;
  opacity: 0;
  margin-left: auto;
  -webkit-transform: translateX(240%);
  -ms-transform: translateX(240%);
  transform: translateX(240%);
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap {
    width: 70%;
    padding-bottom: 20px;
  }
}

#hednav .wrap .navwrap #hed_info {
  border-bottom: 1px solid #E6EAEA;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

#hednav .wrap .navwrap #hed_info .store1 {
  margin: 15px 0;
  color: #fff;
  background: #060337;
  border-bottom: 2px solid #01011b;
  border-radius: 5px;
}

#hednav .wrap .navwrap #hed_info .store1 a {
  opacity: 1 !important;
  flex-direction: column;
  color: #fff;
  padding: 20px 10px;
  position: relative;
  overflow: hidden;
}

#hednav .wrap .navwrap #hed_info .store1 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

#hednav .wrap .navwrap #hed_info .store1 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

#hednav .wrap .navwrap #hed_info .store1 i {
  margin-right: 7px;
}

#hednav .wrap .navwrap #hed_info .store1 .txt {
  line-height: 1;
  letter-spacing: 0.1em;
  font-weight: 800;
  font-size: 33px;
  font-size: 1.71875vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt {
    font-size: 33px;
  }
}

@media only screen and (max-width: 1000px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt {
    font-size: 33px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt {
    font-size: 25px;
  }
}

#hednav .wrap .navwrap #hed_info .store1 .txt2 {
  margin-top: 10px;
  line-height: 1;
  font-size: 13px;
  font-size: 0.67708vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt2 {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1000px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt2 {
    font-size: 13px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .wrap .navwrap #hed_info .store1 .txt2 {
    font-size: 11px;
  }
}

#hednav .wrap .navwrap #hed_info .store1 .txt2 span:first-child {
  margin-right: 5px;
}

#hednav .wrap .navwrap #hed_info .store2 a {
  border-radius: 5px;
  height: 100%;
  background: #ffcc3d;
  border-bottom: 2px solid #cda023;
  font-weight: 700;
  color: #fff;
  padding: 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  position: relative;
  overflow: hidden;
}

#hednav .wrap .navwrap #hed_info .store2 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

#hednav .wrap .navwrap #hed_info .store2 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

#hednav .wrap .navwrap #hed_info .store2 a i {
  vertical-align: middle;
  margin-left: 7px;
}

#hednav .wrap .navwrap #hed_info .store2 a span {
  line-height: 1;
}

#hednav .wrap .navwrap #hed_info .store3 {
  margin-top: 10px;
}

#hednav .wrap .navwrap #hed_info .store3 a {
  border-radius: 5px;
  height: 100%;
  background: #ffcc3d;
  border-bottom: 2px solid #cda023;
  font-weight: 700;
  color: #fff;
  padding: 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1 !important;
  position: relative;
  overflow: hidden;
}

#hednav .wrap .navwrap #hed_info .store3 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

#hednav .wrap .navwrap #hed_info .store3 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

#hednav .wrap .navwrap #hed_info .store3 a i {
  vertical-align: middle;
  margin-left: 7px;
}

#hednav .wrap .navwrap #hed_info .store3 a span {
  line-height: 1;
}

#hednav .wrap .navwrap #hed_info .hoverbtn {
  width: 100%;
}

#hednav .wrap .navwrap #hed_info .hoverbtn a {
  transition: 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

#hednav .wrap .navwrap #nav_global {
  width: 100%;
  margin-top: 0 !important;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}

#hednav .wrap .navwrap #nav_global > ul .pcnone {
  display: block !important;
}

#hednav .wrap .navwrap #nav_global > ul > li {
  text-align: left;
  position: relative;
  width: 100%;
  border-bottom: solid 1px #ddd;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(1) a:before {
  font-size: 16px;
  content: "\f015";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(2) a:before {
  font-size: 16px;
  content: "\f004";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(3) a:before {
  font-size: 16px;
  content: "\f21c";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(4) a:before {
  font-size: 16px;
  content: "\f0b1";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(5) a:before {
  font-size: 16px;
  content: "\f085";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(6) a:before {
  font-size: 16px;
  content: "\f030";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li:nth-child(7) a:before {
  font-size: 16px;
  content: "\f07a";
  line-height: 1;
  font-family: "Font Awesome 5 pro";
  font-weight: 700;
  margin-right: 7px;
}

#hednav .wrap .navwrap #nav_global > ul > li a {
  font-weight: 700;
  line-height: 1.7em;
  color: #222;
  font-size: 17px;
  display: block;
  padding: 25px 36px;
}

#hednav .wrap .navwrap #nav_global > ul > li a:hover {
  background: #ccc;
}

#hednav .wrap .navwrap #nav_global > ul > li ul {
  width: 100%;
  position: static;
  background: none;
}

#hednav .wrap .navwrap #nav_global > ul > li ul li a {
  background: #f7f7f7;
  color: #333;
  font-weight: 500;
  display: block;
}

#hednav .wrap .navwrap #nav_global > ul > li p {
  position: relative;
  display: block;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

#hednav .wrap .navwrap #nav_global > ul > li p:hover:after {
  color: #333;
}

#hednav .wrap .navwrap #nav_global > ul > li p span {
  display: block;
  margin-top: 7px;
  font-size: 0.7em;
}

#hednav .wrap .navwrap #nav_global > ul > li p:after {
  border: solid 1px #ddd;
  font-weight: 100;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  display: block;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: 0;
  width: 77px;
  height: 77px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f6;
}

#hednav .wrap .navwrap #nav_global > ul > li p.open:after {
  content: "\f00d";
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

@media all and (-ms-high-contrast: none) {
  .MenuOpened .global-menu-btn::before {
    content: "\f00d";
    font-family: "Font Awesome 5 pro";
  }
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: auto;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea2 {
  height: 100%;
}

#local-keyvisual {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-position: center;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  width: 100%;
}

#local-keyvisual #local-keyvisual-title h1 {
  background: rgba(0, 0, 0, 0.2);
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1;
  color: #fff !important;
  z-index: 1;
  position: relative;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.4em;
  padding: 130px 10px;
  font-size: 20px;
  text-align: center;
  font-size: 40px;
}

@media only screen and (max-width: 1200px) {
  #local-keyvisual #local-keyvisual-title h1 {
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  #local-keyvisual #local-keyvisual-title h1 {
    font-size: 30px;
  }
}

#local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-title h1 span {
  display: block;
  font-size: 0.6em;
  line-height: 1;
  margin-top: 15px;
}

#local-keyvisual .local-keyvisual-bg {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pan1 {
  margin: 20px 0 50px;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #fff;
}

.canvas-container {
  position: absolute;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  z-index: 9999;
  position: relative;
}

footer#global_footer a {
  color: #ffcc3d;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

iframe {
  vertical-align: middle;
}

.timetable01 {
  display: none;
}

#footer .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #footer .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  #footer .inner {
    display: block;
    padding: 0;
  }
}

#footer .inner .flxR {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  #footer .inner .flxR {
    width: 100%;
  }
}

#footer .inner .flxL {
  width: 47%;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #footer .inner .flxL {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

#footer .inner .flxL #siteID {
  margin-bottom: 10px;
}

#footer .inner .flxL #siteID a {
  text-align: left;
}

#footer .inner .flxL #siteID a img {
  max-width: 50%;
}

#footer .inner .flxL .address, #footer .inner .flxL p {
  line-height: 1.7em;
}

#footer .inner .flxL .address a, #footer .inner .flxL p a {
  color: #fff;
}

#copy {
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  #copy {
    text-align: center;
  }
}

#copy a {
  font-size: 13px;
  color: #fff !important;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media only screen and (max-width: 640px) {
  .progress-wrap {
    right: 10px;
    bottom: 140px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  position: absolute;
  content: url(../images/common/totop.png);
  text-align: center;
  line-height: 100px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  box-shadow: 0px 3px 10px rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #060337;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
}

body.light .progress-wrap::after {
  color: #060337;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #060337;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.btn {
  width: 100%;
  max-width: 300px;
}

.btn a {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  position: relative;
  padding: 15px 30px;
  color: #fff;
  opacity: 1;
  overflow: hidden;
  position: relative;
  background: url(../images/common/pt_2.gif);
}

.btn a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f30b";
  margin-left: 10px;
  margin-right: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
}

.btn a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.btn2 {
  width: 100%;
  max-width: 300px;
}

.btn2 a {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  position: relative;
  padding: 15px 30px;
  color: #fff;
  opacity: 1;
  overflow: hidden;
  position: relative;
  background: url(../images/common/pt_1.gif);
}

.btn2 a:hover:before {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn2 a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f30b";
  margin-left: 10px;
  margin-right: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
}

.btn2 a:before {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: .2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.moveRel {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 834px) {
  .moveRel {
    margin: 0 0 30px;
  }
}

.moveWrap {
  z-index: 999;
  background: #222247;
  position: absolute;
  width: 0;
  top: 0;
}

.cont_1 {
  position: relative;
}

@media only screen and (max-width: 1600px) {
  .cont_1 {
    margin: 130px 0 180px 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_1 {
    padding: 0 10px;
    margin: 80px 0 150px 0 !important;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 {
    margin: 80px 0 !important;
  }
}

.cont_1 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .inner {
    flex-direction: column-reverse;
  }
}

.cont_1 .inner .flxL {
  color: #fff;
  z-index: 1;
  width: 52%;
}

@media only screen and (max-width: 1600px) {
  .cont_1 .inner .flxL {
    width: 54%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner .flxL {
    width: 54%;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1 .inner .flxL {
    width: 100%;
    max-width: 768px;
    margin: 30px auto 0;
    left: 0;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .flxL {
    position: static;
    margin: 0 auto;
  }
}

.cont_1 .inner .flxL .midashi {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner .flxL .midashi {
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1 .inner .flxL .midashi {
    margin-bottom: 15px !important;
  }
}

.cont_1 .inner .flxR {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  z-index: 0;
  width: 65%;
  margin-left: -25%;
}

@media only screen and (max-width: 1600px) {
  .cont_1 .inner .flxR {
    margin-left: 0;
    margin-right: auto;
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_1 .inner .flxR {
    margin-left: 0;
    width: 60%;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1 .inner .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.cont_1 .inner .flxR img {
  width: 100% !important;
}

.cont_1:nth-child(even) .inner {
  flex-direction: row;
}

@media only screen and (max-width: 1000px) {
  .cont_1:nth-child(even) .inner {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1:nth-child(even) .inner {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 1600px) {
  .cont_1:nth-child(even) .inner .flxL {
    left: 0;
    right: inherit;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1:nth-child(even) .inner .flxL {
    width: 100%;
    max-width: 768px;
    margin: 30px auto 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 834px) {
  .cont_1:nth-child(even) .inner .flxL {
    width: 100%;
    margin: 0 auto;
  }
}

.cont_1:nth-child(even) .inner .flxR {
  width: 60%;
  margin-left: 0;
  margin-right: -25%;
}

@media only screen and (max-width: 1600px) {
  .cont_1:nth-child(even) .inner .flxR {
    margin-left: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1:nth-child(even) .inner .flxR {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

.cont_1:nth-child(even) .svg {
  max-width: 700px;
  position: absolute;
  display: block;
  right: 0;
  left: 40%;
  bottom: -20%;
}

@media only screen and (max-width: 1400px) {
  .cont_1:nth-child(even) .svg {
    max-width: 600px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont_1:nth-child(even) .svg {
    bottom: -15%;
    left: 35%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_1:nth-child(even) .svg {
    bottom: inherit;
    top: 50px;
    left: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_1:nth-child(even) .svg {
    top: 35%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1:nth-child(even) .svg {
    top: 35%;
    max-width: 350px;
  }
}

.svg {
  max-width: 700px;
  position: absolute;
  display: block;
  right: 0;
  left: 10%;
  bottom: -20%;
}

@media only screen and (max-width: 1400px) {
  .svg {
    max-width: 600px;
    right: 0;
    left: 50px;
    bottom: -20%;
  }
}

@media only screen and (max-width: 1200px) {
  .svg {
    bottom: -15%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 1000px) {
  .svg {
    bottom: inherit;
    top: 50px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .svg {
    top: 35%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .svg {
    top: 30%;
    max-width: 350px;
  }
}

.svg path {
  transition: 1s;
  fill: transparent;
}

@media all and (-ms-high-contrast: none) {
  .svg path {
    fill: #fff !important;
  }
}

.svg.effect path {
  fill: none;
  stroke: #fff;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  stroke-width: 1;
  animation: hello 3s ease-in forwards;
}

@-webkit-keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

@keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

.cont_2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .cont_2 {
    display: block;
  }
}

.cont_2 .box {
  padding: 80px 50px;
  position: relative;
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .cont_2 .box {
    width: 100%;
  }
}

.cont_2 .box:hover:before {
  opacity: 1;
}

.cont_2 .box:nth-child(1):before {
  background: url(../images/common/img_3.jpg) no-repeat center;
}

.cont_2 .box:nth-child(2):before {
  background: url(../images/common/img_4.jpg) no-repeat center;
}

.cont_2 .box:nth-child(3):before {
  background: url(../images/common/img_5.jpg) no-repeat center;
}

.cont_2 .box:nth-child(4):before {
  background: url(../images/common/img_6.jpg) no-repeat center;
}

.cont_2 .box:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  transition: 1s;
}

.cont_2 .box td {
  border: 0 !important;
  padding: 0 !important;
}

.cont_2 .box article {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 1;
  position: relative;
}

.cont_2 .box .btn2 a {
  margin: 20px auto 0;
  max-width: 400px;
}

.cont_3 .inner {
  max-width: 1000px;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

.newswrap {
  overflow-y: auto;
  height: 350px;
}

@media only screen and (max-width: 834px) {
  .newswrap {
    height: 350px;
  }
}

.news {
  text-shadow: 2px 3px 3px rgba(41, 16, 5, 0.45);
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 10px;
}

.news dl {
  padding: 20px 0;
  border-bottom: 1px dashed #fff;
  display: block !important;
}

.news dl dt {
  font-weight: 700;
  color: #fff;
  line-height: 1;
  padding: 0 0 10px 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.news dl dd {
  line-height: 1.7em;
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_4 {
  background: url(../images/common/bg1_pc.jpg) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
}

@media only screen and (max-width: 640px) {
  .cont_4 {
    background-attachment: inherit;
  }
}

.cont_4 .inner {
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .cont_4 .inner {
    padding: 80px 10px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner {
    display: block;
  }
}

.cont_4 .inner .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_4 .inner .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_4 .inner .flxR {
    width: 100%;
  }
}

.cont_4 .inner .box {
  background: #010007;
}

.cont_4 .inner td {
  padding: 0 !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

.contactform.confirm {
  margin-top: 120px;
}

section div.form_wrap.message {
  margin-top: 120px !important;
  background: none !important;
}

section div.form_wrap.message a {
  color: red !important;
}

.anc01 #anc_1 {
  margin-top: -100px;
  padding-top: 100px;
}

.midashi h2 {
  letter-spacing: 0;
  line-height: 1.7em;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bold;
  font-size: 23px;
}

@media only screen and (max-width: 1200px) {
  .midashi h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.midashi2 h3 {
  color: red;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 1200px) {
  .midashi2 h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi2 h3 {
    font-size: 18px;
  }
}

.midashi3 {
  z-index: 1;
  position: relative;
}

.midashi3 h2 {
  border: 1px solid #34345d;
  background-color: #21213c;
  padding: 14px 14px 15px 14px;
  margin: 8px 0 19px;
  line-height: 1.7em;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi4 {
  margin: 0 auto;
}

.midashi4 h2 {
  line-height: 1.7em;
  text-shadow: 0.707px 0.707px 3px rgba(41, 16, 5, 0.45);
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h2:after {
  display: block;
  content: "";
  width: 50px;
  height: 3px;
  background: #fff;
  margin: 5px auto 0;
}

.midashi5 h2 {
  line-height: 1.7em;
  text-shadow: 0.707px 0.707px 3px rgba(41, 16, 5, 0.45);
  border-bottom: 1px solid #fff;
  color: #fff;
  font-weight: bold;
  font-size: 25px;
}

@media only screen and (max-width: 1200px) {
  .midashi5 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 834px) {
  .midashi5 h2 {
    font-size: 20px;
  }
}

.comp2 dt, .comp2 dd {
  background: none !important;
  border: none !important;
  padding: 1.5em 1em !important;
  font-size: 1rem;
}

.comp2 dt {
  border-bottom: 1px solid #ccc !important;
}

.comp2 dd {
  border-bottom: 1px solid #eee !important;
}

@media only screen and (max-width: 834px) {
  .comp2 dl {
    border: 1px solid #ccc !important;
    display: flex;
    flex-flow: column;
  }
  .comp2 dt {
    border-bottom: 0 !important;
    background: #333 !important;
    font-weight: bold;
    border-bottom: 1px solid #ccc !important;
  }
  .comp2 dt, .comp2 dd {
    padding: 5px !important;
    display: block;
    width: 100% !important;
  }
}

.cast01 {
  display: flex;
  justify-content: space-between;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media only screen and (max-width: 834px) {
  .cast01 {
    flex-wrap: wrap;
  }
}

.cast01:nth-child(odd) {
  flex-direction: row-reverse;
}

.cast01 .flxL {
  width: 50%;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .cast01 .flxL {
    width: 100%;
  }
}

.cast01 .flxL img {
  width: 100% !important;
}

@media only screen and (max-width: 1000px) {
  .cast01 .flxL img {
    height: 100% !important;
    object-fit: cover;
    font-family: "object-fit: cover;";
    position: absolute;
  }
}

@media only screen and (max-width: 640px) {
  .cast01 .flxL img {
    position: static;
  }
}

.cast01 .flxR {
  background: url(../images/contents/pt_3.gif);
  width: 50%;
  padding: 50px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cast01 .flxR {
    padding: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .cast01 .flxR {
    width: 100%;
    padding: 30px;
  }
}

.cast01 .flxR > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.cast01 .flxR > div h2 {
  margin-bottom: 20px;
}

.concept_01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: url(../images/contents/pt_3.gif);
}

@media only screen and (max-width: 1000px) {
  .concept_01 {
    align-items: inherit;
  }
}

@media only screen and (max-width: 640px) {
  .concept_01 {
    display: block;
  }
}

.concept_01 > div {
  width: 32%;
}

@media only screen and (max-width: 1000px) {
  .concept_01 > div {
    width: 50%;
    overflow: hidden;
    position: relative;
  }
  .concept_01 > div:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .concept_01 > div {
    width: 100%;
  }
  .concept_01 > div:nth-child(3) {
    display: block;
  }
}

.concept_01 > div.midashi4 {
  padding: 50px;
}

@media only screen and (max-width: 1000px) {
  .concept_01 > div.midashi4 {
    padding: 30px;
  }
}

.concept_01 > div.midashi4 h2 {
  margin-bottom: 20px;
}

.concept_01 .img img {
  width: 100% !important;
}

@media only screen and (max-width: 1000px) {
  .concept_01 .img img {
    height: 100% !important;
    object-fit: cover;
    font-family: "object-fit: cover;";
    position: absolute;
  }
}

@media only screen and (max-width: 640px) {
  .concept_01 .img img {
    position: static;
  }
}

.cast02 .fancybox {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -2.439024390243902%;
}

@media only screen and (max-width: 834px) {
  .cast02 .fancybox {
    margin-left: 0;
    justify-content: space-between;
  }
}

.cast02 .fancybox .box {
  width: 30.89430894308943%;
  margin-left: 2.439024390243902%;
  margin-bottom: 2.439024390243902%;
}

@media only screen and (max-width: 834px) {
  .cast02 .fancybox .box {
    width: 48%;
    margin-left: 0;
  }
}

.cast03 .fancybox {
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.cast03 .fancybox .box {
  width: 48%;
  margin-bottom: 30px;
}

.fancybox img {
  width: 100% !important;
}

.parts_01 {
  padding: 30px;
  background: #2a2a2a;
}

.parts_01 h3 {
  font-size: 20px;
  font-weight: 700;
}

.parts_02 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .parts_02 {
    flex-wrap: wrap;
  }
}

.parts_02:nth-child(odd) {
  flex-direction: row-reverse;
}

.parts_02 .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .parts_02 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.parts_02 .flxL img {
  width: 100% !important;
}

.parts_02 .flxR {
  width: 58%;
}

@media only screen and (max-width: 834px) {
  .parts_02 .flxR {
    width: 100%;
  }
}

.parts_03 {
  padding: 100px 10px;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.parts_03 h3 {
  font-size: 20px;
  font-weight: 700;
}

.parts_04 {
  padding: 100px 10px;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.parts_04 .fancybox {
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.parts_04 .fancybox .box {
  width: 48%;
  margin-bottom: 30px;
}

.parts_04 .fancybox .box h3 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin: 20px 0 10px;
}

.parts_04 {
  padding: 100px 10px 70px;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.parts_04 .fancybox {
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.parts_04 .fancybox .box {
  width: 48%;
  margin-bottom: 30px;
}

.parts_04 .fancybox .box h3 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  margin: 20px 0 10px;
}

.slider2 {
  display: none;
}

.slider2.slick-initialized {
  display: block;
}

.slider2 .slick-dots {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -2.2556%;
}

.slider2 .slick-slide img {
  width: 100% !important;
  height: 450px !important;
  object-fit: cover;
}

.slider2 .slick-list {
  width: 100%;
}

.slider2 li {
  list-style: none;
  display: inline-block;
  width: 100%;
  max-width: 17.7443%;
  margin-left: 2.2556%;
  margin-bottom: 2.2556%;
}

.slider2 li img {
  opacity: 0.5;
  height: auto;
  object-fit: cover;
}

.slider2 li.slick-active img {
  opacity: 1;
}

.slider2 .slick-prev {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 99;
  top: 200px;
  left: -20px;
  font-size: 20px;
  color: #fff;
  padding: 30px 20px;
  background: url(../images/common/pt_1.gif);
  opacity: 1;
}

@media only screen and (max-width: 834px) {
  .slider2 .slick-prev {
    top: 120px;
    left: 20px;
  }
}

.slider2 .slick-next {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 99;
  top: 200px;
  right: -20px;
  font-size: 20px;
  color: #fff;
  padding: 30px 20px;
  background: url(../images/common/pt_1.gif);
  opacity: 1;
}

@media only screen and (max-width: 834px) {
  .slider2 .slick-next {
    top: 120px;
    right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .slider2 .slick-slide img {
    height: 350px !important;
  }
}

@media screen and (max-width: 468px) {
  .slider2 .slick-slide img {
    height: 250px !important;
  }
}

.stock_01 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .stock_01 {
    display: block;
  }
}

.stock_01 .flxL {
  width: 53%;
}

@media only screen and (max-width: 834px) {
  .stock_01 .flxL {
    width: 100%;
  }
}

.stock_01 .flxR {
  width: 43%;
}

@media only screen and (max-width: 834px) {
  .stock_01 .flxR {
    width: 100%;
  }
}

.stock_01 .flxR .list dl dt, .stock_01 .flxR .list dl dd {
  border: 1px solid #000000 !important;
  justify-content: center !important;
  align-items: center !important;
}

.stock_01 .flxR .list dl dt {
  line-height: 1;
  padding: 13px !important;
  background: #040115 !important;
  color: #fff !important;
}

.stock_01 .flxR .list dl dd {
  line-height: 1;
  padding: 13px !important;
  background: #0b0330 !important;
  color: #fff !important;
}

.stock_01 .flxR .tag {
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-left: -1.6393%;
}

@media only screen and (max-width: 834px) {
  .stock_01 .flxR .tag {
    margin-left: 0;
    justify-content: space-between !important;
  }
}

.stock_01 .flxR .tag .box {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  margin-left: 1.6393%;
  width: 31.6939%;
  margin-bottom: 1.6393%;
  background: url(../images/common/pt_2.gif);
  padding: 10px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .stock_01 .flxR .tag .box {
    margin-left: 0;
    width: 48%;
  }
}

.stock_01 .flxR .tag .box div {
  text-align: center;
}

.rzv500r_01 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 834px) {
  .rzv500r_01 {
    flex-wrap: wrap;
  }
}

.rzv500r_01:nth-child(odd) {
  flex-direction: row;
}

.rzv500r_01 .flxL {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .rzv500r_01 .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.rzv500r_01 .flxL img {
  width: 100% !important;
}

.rzv500r_01 .flxR {
  width: 58%;
}

@media only screen and (max-width: 834px) {
  .rzv500r_01 .flxR {
    width: 100%;
  }
  .rzv500r_01 .flxR .btn {
    width: 100%;
  }
}

.btnwrap .btn {
  align-items: center !important;
  justify-content: space-between !important;
  max-width: 100% !important;
}

.btnwrap .btn .box {
  width: 24%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnwrap .btn .box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .btnwrap .btn .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.rzv500r_02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rzv500r_02 .box {
  width: 32%;
}

@media only screen and (max-width: 834px) {
  .rzv500r_02 .box {
    width: 48%;
  }
  .rzv500r_02 .box:nth-child(3) {
    width: 100%;
    margin-top: 30px;
  }
}

.rzv500r_02 .box img {
  width: 100% !important;
}

.waku {
  padding: 20px;
  border: double 7px #fff;
  font-size: 18px;
}

@media only screen and (max-width: 640px) {
  .waku {
    padding: 20px;
  }
}

.message_02 {
  position: relative;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: 80px;
}

.message_02 .inner3 {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .message_02 .inner3 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 834px) {
  .message_02 .inner3 {
    display: block;
  }
}

.message_02 .flxR {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  width: 35%;
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

@media only screen and (max-width: 1200px) {
  .message_02 .flxR {
    position: static;
  }
}

@media only screen and (max-width: 834px) {
  .message_02 .flxR {
    display: none;
  }
}

.message_02 .flxR img {
  width: 100% !important;
}

.message_02 .flxL {
  width: 80%;
  z-index: 1;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .message_02 .flxL {
    width: 100%;
  }
}

.cause_01 {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/contents/img_4.jpg) no-repeat right center;
  background-size: cover;
}

@media only screen and (max-width: 1200px) {
  .cause_01 {
    padding: 80px;
  }
}

@media only screen and (max-width: 640px) {
  .cause_01 {
    padding: 50px;
  }
}

.cause_01 .inner {
  padding: 200px 20% 200px 10px;
}

@media only screen and (max-width: 1200px) {
  .cause_01 .inner {
    background: rgba(255, 255, 255, 0.6);
    padding: 80px;
  }
}

@media only screen and (max-width: 640px) {
  .cause_01 .inner {
    padding: 50px;
  }
}

.cause_02 {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/contents/img_5.jpg) no-repeat left center;
  background-size: cover;
}

@media only screen and (max-width: 1200px) {
  .cause_02 {
    padding: 80px;
  }
}

@media only screen and (max-width: 640px) {
  .cause_02 {
    padding: 50px;
  }
}

.cause_02 .inner {
  padding: 200px 10px 200px 20%;
}

@media only screen and (max-width: 1200px) {
  .cause_02 .inner {
    background: rgba(255, 255, 255, 0.6);
    padding: 80px;
  }
}

@media only screen and (max-width: 640px) {
  .cause_02 .inner {
    padding: 50px;
  }
}

.annot {
  display: none;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 834px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}
